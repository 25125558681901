import type Sentry from "@sentry/browser";

let SentryInstance: typeof Sentry | undefined;

export default () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const onFirstError = async (event: ErrorEvent) => {
    window.removeEventListener("error", onFirstError);
    reportError(event.error);
  };

  window.addEventListener("error", onFirstError);
};

const initSentry = async () => {
  SentryInstance = await import("@sentry/browser");

  SentryInstance.init({
    dsn: "https://a209630c96c932f94a353059c6704708@o179954.ingest.sentry.io/4506058699243520",
    autoSessionTracking: false,
  });
};

const reportError = async (error: unknown) => {
  console.error(error);
  if (!SentryInstance) await initSentry();
  SentryInstance?.captureException(error);
};
